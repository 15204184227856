
h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", sans-serif;
}


/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about-us .content h2 {
    font-weight: 700;
    font-size: 42px;
    line-height: 60px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.about-us .content h3 {
    font-weight: 500;
    line-height: 32px;
    font-size: 24px;
}
#aboutt {
    
    text-align: justify;
     font-size: 20px;
      line-height: 30px ;
  
}

.about-us .content ul {
    list-style: none;
    padding: 0;
}

#imageo {
  display: inline-block;
  height: 200px;
}
.section-title {
  text-align: center;
  padding-bottom: 30px;
  padding-top: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2::after {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #076817;
  bottom: 0;
  left: calc(50% - 25px);
}

.section-title p {
  margin-bottom: 0;
}
/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  padding: 70px 0 60px;
}

.counts .count-box {
  padding: 30px 30px 25px 30px;
  width: 100%;
  position: relative;
  text-align: center;
  background: #f1f6fe;
}

.counts .count-box i {
  position: absolute;
  top: -28px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  background: #095a24;
  padding: 12px;
  color: #fff;
  border-radius: 50px;
  border: 5px solid #fff;
}

.counts .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 600;
  color: #062b5b;
}

.counts .count-box p {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}


/* faq */

#section-bg{
  background-color: #97e5b0;
}
.faq .faq-list {
  padding: 0 100px;
}

.faq .faq-list ul {
  padding: 0;
  list-style: none;
}

.faq .faq-list li+li {
  margin-top: 15px;
}

.faq .faq-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}

.faq .faq-list a {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
}

.faq .faq-list .icon-help {
  font-size: 24px;
  position: absolute;
  right: 0;
  left: 20px;
  color: lightgray;
}

.faq .faq-list .icon-show,
.faq .faq-list .icon-close {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list a.collapsed {
  color: #343a40;
  transition: 0.3s;
}

.faq .faq-list a.collapsed:hover {
  color: #1bbd36;
  text-decoration: none;
  font-size: large;
}

.faq .faq-list a.collapsed .icon-show {
  display: inline-block;
  
}

.faq .faq-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1200px) {
  .faq .faq-list {
    padding: 0;
  }
}


  @media screen and (max-width:576px) {

#aboutt {

  text-align: left;
  font-size: 1em;
 

}
#imageo{
 display: none;
}
  }