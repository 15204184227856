@import url(https://fonts.googleapis.com/css?family=Nunito:300,400,600,700,800,900|Poppins:300,400,500,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins|Roboto&display=swap);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto',
    sans-serif;
  /* 'Roboto', sans-serif;  change roboto to popin and test it later*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: cente;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#signup{
    margin: 0% auto;
    width: 35%;
    border-radius: 10px;


    
}

@media screen and (max-width:600px) {
  #signup{
      width: 100%;
      height: auto;
  }
      
    }



    .loader{

        border: 3px solid rgb(236, 64, 122);
        /* rgb(235, 41, 106); */
         /* #f3f3f3;  b4 */
        border-radius: 50%;
        border-top: 3px solid #ffffff;
        /* #3498db; b4*/
        width: 15px;
        height: 15px;
        -webkit-animation: spin 2s linear infinite;
                animation: spin 2s linear infinite

    
    }
    @-webkit-keyframes spin{
        0% { -webkit-transform: rotate(0deg); transform: rotate(0deg);}
        100% {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
    }
    @keyframes spin{
        0% { -webkit-transform: rotate(0deg); transform: rotate(0deg);}
        100% {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
    }    
        
#signin{
    margin: 0% auto;
    width: 350px;
    border-radius: 20px;
  
    
}
#loginbtn:hover{
    background-color:#03023b!important;
    color:white!important;
}

@media screen and (max-width:600px) {
    #signin{
        width:95%;
    }
}


#loader {
    margin: 0 auto;

    border: 4px solid rgb(236, 64, 122);
    /* rgb(235, 41, 106); */
    /* #f3f3f3;  b4 */
    border-radius: 50%;
    border-top: 4px solid #ffffff;
    /* #3498db; b4*/
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}


#h1 {
  color: #000000;
  text-align: center;
  font-family: sans-serif;
  text-transform: capitalize;
  font-size: 20px;
  position: relative;
}

#h1:after {
  position: absolute;
  content: "";
  -webkit-animation: Dots 2s cubic-bezier(0, .39, 1, .68) infinite;
  animation: Dots 2s cubic-bezier(0, .39, 1, .68) infinite;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ellipsis span {
  position: absolute;
  top: 13px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis span:nth-child(1) {
  left: 8px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis span:nth-child(2) {
  left: 8px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis span:nth-child(3) {
  left: 32px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis span:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite;
}
@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}

.list-group-flush {
    color: #495057;
}

.active {
    background-color: #e9ecef !;
}

main {
    /* background-color: #EDEDEE; */
    background-color: #0E1E25;

}

.navbar-brand {
    margin-left: 15px;
    color: #2196f3 !important;
}

.adminsidebar-fixed {
    left: 0;
    top: 0;
    height: 100vh;
    width: 270px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    z-index: 1050;
    background-color: #fff;
    padding: 1.5rem;
    padding-top: 0;
}


.flexible-navbar {
    transition: padding-left 0.5s;
    padding-left: 270px;
    background: #fff;
}

#content {
    margin-left: 270px;
    background-color: black;

}


.sidebar-fixed .logo-wrapper img {
    width: 100%;
    padding: 2.5rem;
}

.list-group-item {
    display: block !important;
    transition: background-color 0.3s;
}

.list-group-item:hover {
    color: #f4f5f8;
    text-decoration: none;
    background-color: #1976d2;
}

.list-group-item:hover {
    color: #ebecee;
    text-decoration: none;
    background-color: #1976d2;
}


.list-group .active {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 5px;
}

.card-title {
    font-size: 1.5rem;
    font-weight: 600;
}

.card-subtitle {
    font-size: 1.25rem;
    font-weight: 600;
}

.full {
    height: 70vh;
}

.bad-gateway-row {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 55%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}


.card .view.gradient-card-header {
    padding: 1rem 1rem;
    text-align: center;
}

.card h3,
.card.card-cascade h4 {
    margin-bottom: 0px;
}

.cascading-admin-card .admin-up {
    margin-left: 4%;
    margin-right: 4%;
    margin-top: -20px;
}

.cascading-admin-card .admin-up .fa {
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2), 0 2px 13px 0 rgba(0, 0, 0, 0.19);
}

.cascading-admin-card .admin-up .fa {
    padding: 1.7rem;
    font-size: 2rem;
    color: #fff;
    text-align: left;
    margin-right: 1rem;
    border-radius: 3px;
}

.cascading-admin-card .admin-up .data {
    float: right;
    margin-top: 2rem;
    text-align: right;
}

.admin-up .data p {
    color: #999999;
    font-size: 12px;
}

.classic-admin-card .card-body {
    color: #fff;
    margin-bottom: 0;
    padding: 0.9rem;
}

.classic-admin-card .card-body p {
    font-size: 13px;
    opacity: 0.7;
    margin-bottom: 0;
}

.classic-admin-card .card-body h4 {
    margin-top: 10px;
}

.form-inline {
    -webkit-flex-flow: unset;
            flex-flow: unset
}

.breadcrumb {
    margin: 0;
}

.activeClass .list-group-item {
    z-index: 5;
    color: #fff;
    border-color: #007bff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 5px !important;
    background-color: #007bff !important;
}

.page-footer {
    margin-left: 270px;
}

@media (max-width: 1199.98px) {
    .sidebar-fixed {
        display: none;
    }

    .flexible-content {
        padding-left: 0;
    }

    .flexible-navbar {
        padding-left: 10px;
    }

    #content {
        margin-left: 0px;
    }

    .page-footer {
        margin-left: 0px;
    }

    .card.cascading-admin-card {
        margin-top: 2.5rem;
    }
}

@media (max-width: 576px) {
    .card.cascading-admin-card {
        margin-top: 2.5rem;
    }

    #breadcrumb {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
}
.list-group-item {
    border: 0px;
    /* padding: 2px 0px */
}

/* .list-link{
     box-sizing: border-box;
    padding: 5px 8px;
         display: block;
         
} */
#mobilenav {
    display: none ;
}
.position-fixed {


    /* width: 250px; */
    margin-right: 0px !important;
}

.list-link:hover {
    box-sizing: border-box;
    /* padding: 5px 8px; */
    display: block;
    background-color: #1976d2;
    color: white;
    border-radius: 20px;
}

@media (max-width: 576px) {
    #sidebaradmin {
        display: none;
        position: relative !important;
    }

#mobilenav{
        display: block !important;
    }

      


}

#corousals{
        background-color: black;
         opacity:0.8 ;
         font-size:4em
    }
 #btnhideonsm {
     display: inline-block;
 }
 #btnshowonsm {
     display: none;
 }



    @media screen and (max-width:576px) {
 #corousals {
     background-color: black;
     opacity: 0.8;
     font-size: 1em
 }
 #btncorousal{
    display: none;
    
 }
 #btnhideonsm{
     display: none;
 }
 #btnshowonsm{
     display: block;
 }
    }

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", sans-serif;
}


/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about-us .content h2 {
    font-weight: 700;
    font-size: 42px;
    line-height: 60px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.about-us .content h3 {
    font-weight: 500;
    line-height: 32px;
    font-size: 24px;
}
#aboutt {
    
    text-align: justify;
     font-size: 20px;
      line-height: 30px ;
  
}

.about-us .content ul {
    list-style: none;
    padding: 0;
}

#imageo {
  display: inline-block;
  height: 200px;
}
.section-title {
  text-align: center;
  padding-bottom: 30px;
  padding-top: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2::after {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #076817;
  bottom: 0;
  left: calc(50% - 25px);
}

.section-title p {
  margin-bottom: 0;
}
/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  padding: 70px 0 60px;
}

.counts .count-box {
  padding: 30px 30px 25px 30px;
  width: 100%;
  position: relative;
  text-align: center;
  background: #f1f6fe;
}

.counts .count-box i {
  position: absolute;
  top: -28px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: 24px;
  background: #095a24;
  padding: 12px;
  color: #fff;
  border-radius: 50px;
  border: 5px solid #fff;
}

.counts .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 600;
  color: #062b5b;
}

.counts .count-box p {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}


/* faq */

#section-bg{
  background-color: #97e5b0;
}
.faq .faq-list {
  padding: 0 100px;
}

.faq .faq-list ul {
  padding: 0;
  list-style: none;
}

.faq .faq-list li+li {
  margin-top: 15px;
}

.faq .faq-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}

.faq .faq-list a {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
}

.faq .faq-list .icon-help {
  font-size: 24px;
  position: absolute;
  right: 0;
  left: 20px;
  color: lightgray;
}

.faq .faq-list .icon-show,
.faq .faq-list .icon-close {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list a.collapsed {
  color: #343a40;
  transition: 0.3s;
}

.faq .faq-list a.collapsed:hover {
  color: #1bbd36;
  text-decoration: none;
  font-size: large;
}

.faq .faq-list a.collapsed .icon-show {
  display: inline-block;
  
}

.faq .faq-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1200px) {
  .faq .faq-list {
    padding: 0;
  }
}


  @media screen and (max-width:576px) {

#aboutt {

  text-align: left;
  font-size: 1em;
 

}
#imageo{
 display: none;
}
  }
#btnAndBook{
   display: none;

}
hr{
    display: none;
}

 @media screen and (max-width:576px) {

#btnAndBook {
    display: block;
    margin: 0% auto;
  
    text-align: center;
}
hr{
    display: block!important;
}


 }

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-wrap {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
}

.contact .info {
    background: #fff;
}

.contact .info i {
    font-size: 20px;
    color: #1bbd36;
    float: left;
    width: 44px;
    height: 44px;
    border: 1px solid #1bbd36;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 50px;
    transition: all 0.3s;
}

.contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #111;
}

.contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #444444;
}

.contact .info:hover i {
    background: #1bbd36;
    color: #fff;
}

.contact .php-email-form {
    width: 100%;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
    padding: 30px;
    background: #fff;
}

.contact .php-email-form .form-group {
    padding-bottom: 8px;
}

.contact .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
}

.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .error-message br+br {
    margin-top: 25px;
}

.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
}

.contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 4px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
    border-color: #1bbd36;
}

.contact .php-email-form input {
    height: 44px;
}

.contact .php-email-form textarea {
    padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
    background: #1bbd36;
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
}

.contact .php-email-form button[type="submit"]:hover {
    background: #2ae149;
}

@-webkit-keyframes animate-loading {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes animate-loading {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,102,203, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.custom-toggler.navbar-toggler {
    border-color: rgb(255, 102, 203);
}
#header {
    background: #fff;
    transition: all 0.5s;
    z-index: 997;
    /* padding: 15px 0; */
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
    color: #fff;
    font-size: 14px;
    background: #111;
}

#footer .footer-top {
    padding: 60px 0 30px 0;
    background: #1e1e1e;
}

#footer .footer-top .footer-contact {
    margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
    font-size: 22px;
    margin: 0 0 10px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff;
}

#footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Roboto", sans-serif;
    color: rgba(255, 255, 255, 0.7);
}

#footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
}

#footer .footer-top .footer-links {
    margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #1ed33c;
    font-size: 18px;
    line-height: 1;
}

#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

#footer .footer-top .footer-links ul a {
    color: rgba(255, 255, 255, 0.6);
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    color: #fff;
}

#footer .footer-newsletter {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.7);
}

#footer .footer-newsletter h4 {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
}

#footer .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4px 0 0 4px;
    text-align: left;
}

#footer .footer-newsletter form input[type="email"] {
    border: 0;
    padding: 4px 8px;
    width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type="submit"] {
    position: absolute;
    top: 0;
    right: -4px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #1bbd36;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type="submit"]:hover {
    background: #2ae149;
}

#footer .credits {
    padding-top: 5px;
    font-size: 13px;
    color: #fff;
}

#footer .social-links a {
    font-size: 18px;
    display: inline-block;
    background: rgba(255, 255, 255, 0.08);
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

#footer .social-links a:hover {
    background: #1bbd36;
    color: #fff;
    text-decoration: none;
}

