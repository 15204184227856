.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,102,203, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.custom-toggler.navbar-toggler {
    border-color: rgb(255, 102, 203);
}
#header {
    background: #fff;
    transition: all 0.5s;
    z-index: 997;
    /* padding: 15px 0; */
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}