#btnAndBook{
   display: none;

}
hr{
    display: none;
}

 @media screen and (max-width:576px) {

#btnAndBook {
    display: block;
    margin: 0% auto;
  
    text-align: center;
}
hr{
    display: block!important;
}


 }
